import { useCallback, useEffect } from 'react'

import { Loader } from '@mantine/core'

import { Metadata, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { BEFORE_LOGOUT } from 'src/graphql/users.graphql'

const LoginPage = () => {
  const { logIn, logOut, currentUser } = useAuth()

  const [beforeLogout] = useMutation(BEFORE_LOGOUT, {
    onCompleted: async () => {
      await logOut({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    },
  })

  const onLogin = useCallback(async () => {
    await logIn()
  }, [logIn])

  const onLogOut = useCallback(async () => {
    await beforeLogout()
  }, [beforeLogout])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        currentUser &&
        !currentUser.roles.includes('ADMIN') &&
        !currentUser.roles.includes('RECRUITER')
      ) {
        onLogOut()
      } else {
        onLogin()
      }
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [onLogin])

  return (
    <>
      <Metadata
        title="Login"
        description="Login page"
        robots="noindex,nofollow"
      />
      <div className="flex min-h-screen items-center justify-center">
        <Loader></Loader>
      </div>
    </>
  )
}

export default LoginPage
